<template>
  <section class="">
    <docs-create v-model="docs" title="Documentni o'zgartirish"></docs-create>
    <div class="d-flex container">
      <a-button class="bg-danger text-white" @click="deleteDocument"
        >O'chirish</a-button
      >
      <a-button class="bg-warning text-white ml-2" @click="editDocument"
        >O'zgartirish</a-button
      >
    </div>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos dokument sarlavha, sarlavha_en,sarlavha_ru, sarlavha_uz va
        rasmni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import DocsCreate from '../../components/Document/DocsCreate.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    DocsCreate,
  },
  props: ['docsId'],
  data() {
    return {
      inValid: false,
      docs: {
        title: '',
        title_en: '',
        title_ru: '',
        title_uz: '',
        image: '',
      },
    };
  },

  methods: {
    ...mapActions({
      getOneDocs: 'document/getOneDocs',
      patchDocument: 'document/patchDocument',
      deleteOneDocument: 'document/deleteOneDocument',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    deleteDocument() {
      this.deleteOneDocument(this.docsId)
        .then(() => {
          this.$notification['success']({
            message: 'Success',
            description: "Serverda dokument o'chirildi :)",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Document' });
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Document' });
          }, 1000);
        });
    },
    editDocument() {
      const obj = {
        title: this.docs.title,
        title_en: this.docs.title_en,
        title_ru: this.docs.title_ru,
        title_uz: this.docs.title_uz,
        image: this.docs.image,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }

      if (!obj.image.obj) {
        delete obj.image;
      } else {
        obj.image = this.docs.image.files;
      }
      this.patchDocument({ obj, id: this.docsId })
        .then(() => {
          this.$notification['success']({
            message: "Dokument o'zgardi",
            description: "Serverda dokument o'zgartirildi ):",
          });
          setTimeout(() => {
            this.$router.push({ name: 'Document' });
          }, 1000);
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: "Serverda Xatolik Bo'ldi ):" + error.message,
          });
          setTimeout(() => {
            this.$router.push({ name: 'Document' });
          }, 1000);
        });
    },
  },

  created() {
    this.getOneDocs(this.docsId)
      .then((res) => {
        this.docs = res;
      })
      .catch((error) => {
        console.log(error);
        this.$notification['error']({
          message: 'Xatolik',
          description:
            'Serverda xatolik yuz berdi ): birozdan keyin urining' +
            ' ' +
            error.message,
        });

        setTimeout(() => {
          this.$router.push({ name: 'Document' });
        }, 1000);
      });
  },
};
</script>
